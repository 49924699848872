import React from "react";
import "./Footer.css";
import { Link } from "react-scroll";
import { Button } from "../Button/Button";

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Mapa stránky</h2>
            <a to="/sign-up">Úvod</a>
            <a to="services">Služby</a>
            <a to="about">O nás</a>
            <a to="galery">Galéria</a>
            <a to="contact">Kontakty</a>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Sociálne Média</h2>
            <a href="https://www.instagram.com/profijazda_sk_italia/">
              Instagram
            </a>
            <a href="https://www.facebook.com/profijazdask.italia">Facebook</a>
            <a href="https://youtube.com/channel/UCkyHWG-lI47FIS_49E3i_cA">
              Youtube
            </a>
          </div>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link
              to="home"
              className="navbar-logo"
              smooth={true}
              duration={1000}
            >
              <span className="left">Profi</span>
              <span className="middle">jazda.sk</span>
              <span className="right">Italia</span>
            </Link>
          </div>
          <small className="website-rights">PROFIJAZDA © 2024</small>
          <div className="social-icons">
            <a
              className="social-icon-link facebook"
              href="https://www.facebook.com/profijazdask.italia"
              target="_blank"
              aria-label="Facebook"
            >
              <i className="fab fa-facebook-square" />
            </a>
            <a
              className="social-icon-link instagram"
              href="https://www.instagram.com/profijazda_sk_italia/"
              target="_blank"
              aria-label="Instagram"
            >
              <i className="fab fa-instagram" />
            </a>
            <a
              className="social-icon-link youtube"
              href="https://youtube.com/channel/UCkyHWG-lI47FIS_49E3i_cA"
              target="_blank"
              aria-label="Youtube"
            >
              <i className="fab fa-youtube" />
            </a>
            <Link
              className="social-icon-link twitter"
              to="contact"
              smooth={true}
              duration={1000}
            >
              <i className="fas fa-envelope-square" />
            </Link>
            <Link
              className="social-icon-link twitter"
              to="contact"
              smooth={true}
              duration={1000}
            >
              <i className="fas fa-phone-square-alt" />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}
