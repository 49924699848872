import React from 'react';
import AboutItem from './ServicesItem';
import './Services.css';

export default function About() {
    return (
        <div id="services" className="cards">
            <div className="services-header">
                <h1>NAŠA PONUKA SLUŽIEB</h1>
            </div>
            <div className="cards-container">
                <div className="cards-wrapper">
                    <ul className="cards-items">
                        <AboutItem 
                        src="images/sluzby/1.jpeg"
                        text='Ponúkame komfortnú prepravu 9-miestnym autom s dostatočným priestorom pre Vás a Vašu batožinu.'
                        label='Komfort'
                        path='/about'
                        />
                        <AboutItem 
                        src="images/sluzby/3.jpg"
                        text='Ponúkame pravidelné jazdy Slovensko - Taliansko (Udine Grado Lignano Caorle Jesolo Bibione Venezia Padova Ravenna Bologna Rimini Ancona Firenze Roma okolie trasy alebo dohodou).'
                        label='Trasy'
                        path='/about'
                        />
                    </ul>
                    <ul className="cards-items">
                        <AboutItem 
                        src="images/sluzby/4.jpg"
                        text='Preprava na letiská do EÚ (Katowice, Wien, Bratislava, Praha, Budapešť).'
                        label='Letisko'
                        path='/about'
                        />
                        <AboutItem 
                        src="images/sluzby/2.jpg"
                        text='Ponúkame prepravu za prácou, za zábavou, na dovolenku atď.'
                        label='Dovolenka'
                        path='/about'
                        />
                        <AboutItem 
                        src="images/sluzby/5.jpg"
                        text='Ponúkame prepravu tovaru, zásielok, dokumentov a pod.'
                        label='Zásielka'
                        path='/about'
                        />
                    </ul>
                </div>
            </div>
        </div>
    )
}
