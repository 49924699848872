import Img1 from './1.JPG';
import Img2 from './2.JPG';
import Img3 from './3.JPG';
import Img4 from './4.JPG';
import Img5 from './5.JPG';
import Img6 from './6.JPG';
import Img7 from './7.JPG';
import Img8 from './8.JPG';

export const photos = [
    {
      src: Img1,
      width: 4,
      height: 3
    },
    {
      src: Img2,
      width: 1,
      height: 1
    },
    {
      src: Img3,
      width: 3,
      height: 4
    },
    {
      src: Img4,
      width: 3,
      height: 4
    },
    {
      src: Img5,
      width: 3,
      height: 4
    },
    {
      src: Img6,
      width: 1,
      height: 1
    },
    {
      src: Img7,
      width: 3,
      height: 4
    },
    {
        src: Img8,
        width: 4,
        height: 3
      },
]