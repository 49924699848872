import React, {useState, useEffect} from 'react';
import {Link} from 'react-scroll';
import { Button } from '../Button/Button';
import './Navbar.css';

export default function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        }else {
            setButton(true);
        }
    }

    window.addEventListener('resize', showButton);

    return (
        <>
           <nav className="navbar">
               <div className="navbar-container"> 
                    <Link to="home" className="navbar-logo" onClick={closeMobileMenu} smooth={true} duration={1000}>
                        <span className="left">Profi</span>
                        <span className="middle">jazda.sk</span>
                        <span className="right">Italia</span>
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className="nav-item">
                            <Link to="home" className='nav-links' onClick={closeMobileMenu} smooth={true} duration={1000}>
                                Úvod
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="services" className='nav-links' onClick={closeMobileMenu} smooth={true} duration={1000}>
                            Služby
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="about" className='nav-links' onClick={closeMobileMenu} smooth={true} duration={1000}>
                            O nás
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="gallery" className='nav-links' onClick={closeMobileMenu} smooth={true} duration={1000}>
                            Galéria
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="contact" className='nav-links' onClick={closeMobileMenu} smooth={true} duration={1000}>
                                Kontakt
                            </Link>
                        </li>
                        <li className="nav-item" onClick={closeMobileMenu}>
                            <div className="nav-social">
                                <a href="https://www.instagram.com/profijazda_sk_italia/">
                                    <i className="fab fa-facebook-square"></i>
                                </a>
                                <a href="https://www.instagram.com/profijazda_sk_italia/">
                                    <i className="fab fa-instagram-square"></i>
                                </a>
                                <a href="https://youtube.com/channel/UCkyHWG-lI47FIS_49E3i_cA">
                                    <i className="fab fa-youtube-square"></i>
                                </a>
                                <Link to="contact" smooth={true} duration={1000} onClick={closeMobileMenu}>
                                    <i className="fas fa-envelope-square"></i>
                                </Link>
                                <Link href="contact" smooth={true} duration={1000} onClick={closeMobileMenu}>
                                    <i className="fas fa-phone-square-alt"></i>
                                </Link>
                            </div>
                        </li>
                    </ul>
               </div>
            </nav> 
        </>
    )
}