import React from 'react';
import '../../App.css';
import Intro from "../Intro/Intro";
import About from '../About/About';
import Services from '../Services/Services';
import Gallery from '../Gallery/GalleryComponent'; 
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';

export default function Home() {
    return (
        <>
            <Intro />
            <Services />
            <About />
            <Gallery />
            <Contact />
            <Footer />
        </>
    )
}
