import React from 'react'
import './About.css'

export default function AboutItem(props) {
    return (
        <>
            <li className="about-item">
                <div className="about-item-link">
                    <i className={props.icon}></i>
                    <h5 className="about-item-text">{props.text}</h5>
                </div>
            </li>
        </>
    )
}
