import React from 'react'
import {Link} from 'react-router-dom';
import './Services.css'

export default function AboutItem(props) {
    return (
        <>
            <li className="cards-item">
                <a className="cards-item-link" href="{props.path}">
                    <figure className="cards-item-pic-wrap" data-category={props.label}>
                        <img src={props.src} alt="Travel Image" className="cards-item-img"/>
                    </figure>
                    <div className="cards-item-info">
                        <h5 className="cards-item-text">{props.text}</h5>
                    </div>
                </a>
            </li>
        </>
    )
}
