import React, {useState, useEffect} from 'react';
import {Link} from 'react-scroll';
import "./About.css";
import AboutItem from "./AboutItem";

export default function About() {

    const [clickRule, setClickRule] = useState(true);

    const handleClickRule = () => setClickRule(!clickRule);
    const closeRules = () => setClickRule(false);

  return (
    <div id="about" className="about" onLoad={closeRules}>
    <div className={clickRule ? 'rule-container active-rule' : 'rule-container'}>
        <div className="rule-wrap">
          <div className="rule-btn">
            <i className="fas fa-times" onClick={handleClickRule}/>
          </div>
          <div className="rule-text">
            <h3>Prepravný poriadok na vykonávanie taxislužby</h3>
            <p>
              Vypracovaný v súlade so zákonom č. 56/2012 Z. z. o cestnej
              doprave, zákonom č. 8/2009 Z. z. o cestnej premávke a zákonom č.
              40/1964 Zb. Občiansky zákonník. <br /> <br /> 
            </p>
            <p>
            Čl. I. Úvodné ustanovenia 1.<br /> 
              Prepravný poriadok upravuje prepravné podmienky dopravcu potrebné
              na uzatvorenie prepravnej zmluvy v taxislužbe. 2. Zverejnený
              prepravný poriadok je súčasťou návrhu dopravcu na uzatvorenie
              prepravnej zmluvy a po jej uzatvorení je jeho obsah súčasťou
              zmluvných práv a povinností účastníkov. <br /> <br /> 
            </p>
            <p>
            Čl. II. Vymedzenie<br /> 
              základných pojmov Taxislužba je prevádzkovanie osobnej dopravy
              vozidlami taxislužby ako prepravy jednotlivých cestujúcich do
              cieľového miesta podľa zmluvy o preprave osôb. Dopravca má
              prevádzkovú povinnosť v rozsahu koncesie, prepravnú povinnosť v
              rozsahu prepravného poriadku a tarifnú povinnosť podľa tarify.<br /> <br />  
            </p>
            <p>
            Čl. III. Povinnosti dopravcu<br />  1. Dopravca je povinný: a) prevádzkovať
              osobnú cestnú dopravu v taxislužbe podľa prepravného poriadku, b)
              zabezpečiť zverejnenie a sprístupnenie prepravného poriadku na
              svojom webovom sídle, c) prepravovať cestujúcich podľa platného
              cenníka taxislužby a na požiadanie cestujúceho vydať potvrdenku o
              vykonanej preprave a zaplatení cestovného, d) starať sa o
              bezpečnosť, pohodlie a pokojnú prepravu cestujúcich a o prepravu
              ich batožiny, e) označiť každé prevádzkované vozidlo svojim
              obchodným menom, f) byť poistený pre prípad zodpovednosti za škodu
              spôsobenú prevádzkovaním osobnej cestnej dopravy v taxislužbe,
              cestujúcim a tretím osobám, g) ak na základe zmluvy s dopravcom
              poskytuje osobnú cestnú dopravu v taxislužbe vykonávajúci
              dopravca, ustanovenia tohto prepravného poriadku sa naňho vzťahujú
              v rovnakom rozsahu. <br /> <br /> 
            </p>
            <p>
            Čl. IV. Vozidlo taxislužby<br />  1. Vozidlom
              taxislužby môže byť len vozidlo, ktoré: a) je uvedené v koncesii a
              má v koncesii pridelené evidenčné číslo vozidla taxislužby; toto
              číslo musí byť umiestnené vo vozidle taxislužby na mieste
              viditeľnom pre cestujúceho, b) je na predných ľavých a pravých
              dverách označené obchodným menom prevádzkovateľa taxislužby a
              telefónnym číslom dispečingu objednávkovej služby, ak ju
              prevádzkovateľ taxislužby zriadil; inak telefónnym číslom
              dopravcu, c) má pevne zabudovaný funkčný taxameter spĺňajúci
              požiadavky na určené meradlá, ktorý umožňuje cestujúcemu počas
              prepravy sledovať okamžitú cenu cestovného a ktorý vydáva
              potvrdenku o zaplatenom cestovnom; taxameter nemusí mať vozidlo
              taxislužby použité na prepravu skupiny cestujúcich, ktorí
              zaplatili cestovné pred začatím prepravy alebo na obvyklých
              miestach na pravidelnej trase prepravy, d) má na pravých predných
              dverách a vo vnútri vozidla taxislužby na mieste viditeľnom pre
              cestujúceho základnú sadzbu cestovného; to neplatí, ak ide o
              vozidlo taxislužby použité na prepravu skupiny cestujúcich, ktorí
              zaplatili cestovné pred začatím prepravy alebo na obvyklých
              miestach na pravidelnej trase prepravy, 2 e) je poistené pre
              prípad vzniku zodpovednosti za škodu spôsobenú cestujúcemu na
              zdraví alebo na jeho majetku, f) je vybavené pevným alebo
              odnímateľným strešným svietidlom žltej farby s nápisom TAXI, g)
              musí umožniť prepravu najmenej 50 kg cestovnej batožiny pri plnom
              obsadení v rámci celkovej hmotnosti vozidla alebo musí mať
              batožinový priestor alebo ložný priestor s objemom najmenej 375
              dm³. <br /> <br /> 
            </p>
            <p>
            Čl. V. Výkon taxislužby<br />  1. Dopravca má prepravnú a
              prevádzkovú povinnosť, ak sú splnené podmienky prepravného
              poriadku. 2. Dopravca môže ponúkať prepravu cestujúcich na určenom
              stanovišti alebo objednávkovou službou, pričom zmluvu o preprave
              osôb môže vodič uzavrieť s cestujúcim kdekoľvek. 3. Preprava sa
              uskutočňuje po najkratšej trase, ktorú umožňuje dopravná situácia;
              inú trasu prepravy môže použiť len so súhlasom cestujúceho alebo
              na jeho návrh, alebo ak ide o vopred známu pravidelnú trasu
              prepravy skupiny cestujúcich. Po uzavretí zmluvy o preprave s
              cestujúcim možno na prepravu po rovnakej trase vziať ďalšieho
              cestujúceho len so súhlasom cestujúceho alebo na jeho návrh, alebo
              ak ide o vopred známu pravidelnú trasu prepravy skupiny
              cestujúcich. 4. Počas prepravy vodič a cestujúci nesmú v osobnom
              aute taxislužby fajčiť, piť, jesť a kŕmiť prepravované zvieratá.
              Na prednom sedadle je zakázané cestujúcemu počas jazdy manipulácia
              s príručnou batožinou, novinami, mapami alebo inými predmetmi,
              ktoré by mohli obmedziť výhľad vodiča pri vedení vozidla
              taxislužby. 5. V priestore pre cestujúcich možno prepravovať
              príručnú batožinu. Vodič určí či sa batožina prepraví ako príručná
              resp. cestovná batožina v batožinovom priestore. V prípade ak
              vodič určí ako miesto prepravy batožinový priestor, je cestujúci
              povinný upozorniť na osobitnú povahu batožiny, najmä na jej obsah
              a hodnotu, a ak vyžaduje, aby sa s ňou určitým spôsobom
              zaobchádzalo alebo aby sa ukladala v určitej polohe. Batožinu a
              iné veci nakladá a vykladá, umiestňuje alebo pripevňuje vodič
              vozidla taxislužby; prípadne na požiadanie je cestujúci povinný
              poskytnúť vodičovi pri ukladaní alebo vykladaní primeranú pomoc.
              Ak má vodič pochybnosti o prepravovanej batožine je oprávnený
              presvedčiť sa v prítomnosti cestujúceho o jej povahe a obsahu. Ak
              cestujúci odmietne preskúmanie batožiny alebo ak sa pri jej
              preskúmavaní zistí že veci (zvieratá), ktoré cestujúci chce
              prepravovať, sú z prepravy vylúčené, cestujúci je ich povinný z
              osobného vozidla taxislužby odstrániť. Ak cestujúci neuposlúchne
              pokyn, odstránenie batožiny zabezpečí oprávnená osoba. V takomto
              prípade môže oprávnená osoba cestujúceho vylúčiť z ďalšej cesty.
              6. Z prepravy sú vylúčené: a) veci, preprava ktorých je zakázaná v
              zmysle všeobecne záväzných právnych predpisov, b) nabité zbrane, s
              výnimkou strelných zbraní príslušníkov ozbrojených síl a polície,
              pre prepravu ktorých platia osobitné predpisy, c) veci, ktoré môžu
              ohroziť bezpečnosť prevádzky alebo poškodiť, prípadne znečistiť
              cestujúcich alebo vozidlo pre nevhodný spôsob balenia, d) veci,
              ktoré svojím zápachom, odpudzujúcim vzhľadom a pod. by mohli byť
              cestujúcim na ťarchu, e) batožiny, ktorých celková hmotnosť
              prevyšuje 50 kg. 7. Vo vozidle taxislužby sa môžu na miestach na
              to vyhradených prepravovať osoby len do prípustnej užitočnej
              hmotnosti vozidla, pritom počet prepravovaných osôb nesmie byť
              vyšší, ako je počet miest uvedených v osvedčení o evidencii časť I
              a časť II, alebo v technickom osvedčení vozidla. 8. Na prednom
              sedadle vedľa vodiča možno prepravovať iba cestujúceho, ktorého
              umožňujú prepravovať predpisy o bezpečnosti a plynulosti cestnej
              premávky. 9. O vykonanej preprave je vodič taxislužby povinný na
              požiadanie vydať cestujúcemu potvrdenie, ktoré obsahuje najmä: a)
              číslo dokladu, b) evidenčné číslo vozidla, c) dátum jazdy, 3 d)
              východiskové a cieľové miesto prepravy, e) zaplatené cestovné, f)
              podpis vodiča taxislužby, ktorý prepravu vykonal. 10.Pri výkone
              taxislužby je vodič povinný mať vo vozidle taxislužby tieto
              doklady: a) osvedčenie o evidencii vozidla, b) osvedčenie o
              technickej kontrole, c) osvedčenie o emisnej kontrole, d) doklad o
              povinnom zmluvnom poistení zodpovednosti za škodu spôsobenú
              prevádzkou motorového vozidla, e) kópiu koncesnej listiny, f)
              potvrdenie o overení taxametra, g) ID kartu vodiča taxislužby
              umiestnenú na viditeľnom mieste pre cestujúceho, h) doklad o
              zaplatení cestovného v taxislužbe. <br /> <br /> 
            </p>
            <p>
            Čl. VI. Zmluva o preprave osôb<br /> 
              1. Zmluvný vzťah medzi dopravcom a cestujúcim vzniká na základe
              uzavretej zmluvy o preprave osôb podľa § 760 až 764 Občianskeho
              zákonníka ( ďalej len „prepravná zmluva“ ). 2. Dopravca môže
              uzavrieť zmluvu o preprave osôb s cestujúcimi prostredníctvom
              vodiča taxislužby na stanovišti taxislužby, alebo kdekoľvek na
              území určenom v koncesii, kde sa počas jazdy bez cestujúceho
              nachádza s vozidlom taxislužby; vo svojom sídle, prípadne
              prostredníctvom dispečingu. 3. Pred uskutočnením prepravy a
              uzavretím prepravnej zmluvy má cestujúci právo oboznámiť sa s
              platným cenníkom taxislužby. O vykonanej preprave je vodič
              taxislužby povinný na požiadanie vydať cestujúcemu potvrdenku
              (doklad), ktorá obsahuje údaje podľa čl. V. ods. 9 prepravného
              poriadku. 4. Uzavretím prepravnej zmluvy vzniká dopravcovi
              povinnosť riadne a včas prepraviť cestujúceho do miesta určenia
              podľa zmluvných podmienok a podmienok prepravného poriadku.
              Odmietnuť uzavretie prepravnej zmluvy a vykonanie prepravy môže
              vodič vozidla taxislužby podľa podmienok čl. VIII. prepravného
              poriadku. 5. Uskutočnením prepravy na základe uzavretej prepravnej
              zmluvy a podľa podmienok prepravného poriadku, vzniká cestujúcemu
              povinnosť zaplatiť určené cestovné podľa cenníka taxislužby.
              Odmietnutie zaplatenia cestovného je vymáhateľné súdnou cestou.<br /> <br /> 
            </p>
            <p>
            Čl. VII. Odstúpenie od zmluvy<br />  1. Dopravca môže od uzatvorenej
              zmluvy o preprave odstúpiť ak nie sú cestujúcim splnené podmienky
              prepravnej zmluvy alebo ustanovenia prepravného poriadku. 2. Vodič
              vozidla taxislužby môže od uzatvorenej prepravnej zmluvy odstúpiť,
              ak cestujúci počas prepravy napriek upozorneniu vodiča vo vozidle
              taxislužby fajčí, konzumuje jedlo a nápoje resp. nedodržiava
              ustanovenia prepravného poriadku na zabezpečenie bezpečnej
              prepravy, ohrozuje bezpečnosť vodiča, znečisťuje interiér vozidla,
              bezdôvodne mení trasu a cieľ prepravy alebo inak vzbudzuje obavy o
              bezpečnosť vodiča. 3. Cestujúci môže od uzatvorenej zmluvy o
              preprave odstúpiť, ak dopravca alebo vodič vozidla taxislužby
              porušil zmluvné podmienky alebo podmienky prepravného poriadku.<br /> <br /> 
              
            </p>
            <p>
            Čl. VIII. Odmietnutie prepravy<br />  1. Vodič vozidla taxislužby môže
              odmietnuť vykonať prepravu alebo nedokončiť začatú prepravu, ak: 4
              a) to neumožňuje technický stav a priepustnosť cesty alebo
              bezpečnosť a plynulosť cestnej premávky na trase prepravy, najmä v
              dôsledku poveternostných vplyvov, poškodenia pozemnej komunikácie
              alebo dopravnej nehody, b) správanie sa cestujúceho, najmä ak je
              agresívny alebo ozbrojený, alebo čas prepravy, cieľové miesto,
              trasa prepravy alebo iné okolnosti vzbudzujú u vodiča obavu o
              svoje zdravie, o bezpečnosť prepravy alebo o vozidlo taxislužby,
              c) vzhľadom na stav cestujúceho hrozí znečistenie vozidla
              taxislužby alebo obťažovanie vodiča počas jazdy, d) cestujúci
              napriek upozorneniu vo vozidle taxislužby fajčí, konzumuje jedlo a
              nápoje alebo kŕmi prepravované zviera alebo na prednom sedadle
              manipuluje s príručnou batožinou, novinami, mapou alebo inou
              vecou, ktorou možno obmedziť výhľad vodiča alebo ohroziť vedenie
              vozidla taxislužby, e) cestujúci má batožinu, ktorú vzhľadom na
              jej početnosť, veľkosť, hmotnosť alebo tvar nie je možné naraz
              prepraviť, alebo chce prepravovať zvieratá, ktoré vzhľadom na ich
              veľkosť, početnosť alebo správanie nie je možné prepraviť v
              priestore pre cestujúcich ani v batožinovom priestore. <br /> <br /> 
            </p>
            <p>
            Čl. IX.
              Nakladanie s nájdenými vecami<br />  Vodič taxislužby v prípade, ak sa
              nájde stratená vec cestujúceho vo vozidle taxislužby resp.
              opustená batožina je povinný ju vydať vlastníkovi. Ak vlastník
              nájdenej veci nie je známy alebo ak sa neprihlási v deň nálezu,
              vodič je povinný vec odovzdať dopravcovi (prevádzkovateľovi
              taxislužby). Ak sa prihlási ten, kto vec stratil alebo zabudol v
              aute taxislužby, a nie sú pochybnosti o hodnovernosti jeho
              tvrdenia, vec sa mu vydá.<br /> <br /> 
            </p>
            <p>
            Čl. X. Zodpovednosť<br />  1. Za porušenie
              povinnosti taxislužby prepraviť podľa prepravného poriadku
              cestujúceho riadne a včas zodpovedá dopravca podľa § 763 ods. 2
              Občianskeho zákonníka. V prípade, že došlo k neodôvodnenému
              meškaniu alebo neuskutočneniu prepravy z viny dopravcu alebo
              vodiča taxislužby, je dopravca povinný nahradiť škodu vzniknutú
              cestujúcemu tým, že preprava nebola vykonaná včas, nasledovne: a)
              náhrada škody za omeškanie bude riešená pomernou zľavou na
              zaplatenom cestovnom, b) náhrada škody za neuskutočnenie prepravy
              bude riešená úhradou cestovného podľa cenníka taxislužby. 2.
              Svojej zodpovednosti sa dopravca zbaví ak preukáže, že škode
              nemohol zabrániť ani pri vynaložení všetkého úsilia, ktoré od neho
              možno požadovať.<br />  <br /> 
            </p>
            <p>
            Čl. XI. Reklamácie, sťažnosti, škoda<br />  1. Cestujúci
              alebo ten, kto je oprávnený z prepravy alebo v súvislosti s
              prepravou podávať reklamácie, musí ju uplatniť u dopravcu písomne
              a to najneskôr do 30 dní od vykonania prepravy. V reklamácii musí
              oprávnený vymedziť svoje požiadavky a stručne ich zdôvodniť,
              pričom musí priložiť doklady osvedčujúce oprávnenosť nároku. Ak
              nemá reklamácia všetky náležitosti dopravca ihneď vyzve
              reklamujúceho na jej doplnenie v stanovenej lehote. Ak sa
              reklamácia nedoplní a nezašle v stanovenej lehote nie kratšej ako
              8 dní, považuje sa za nepodanú. 2. Ak cestujúci, alebo ten, kto je
              oprávnený z prepravy alebo v súvislosti s prepravou podávať
              sťažnosť, musí ju uplatniť u dopravcu písomne, najneskôr do 7
              kalendárnych dní od skutočnosti, na ktorú sa sťažnosť vzťahuje. 3.
              Ak cestujúcemu vznikne počas prepravy škoda na zdraví alebo na
              batožinách prepravovaných spoločne s ním alebo na veciach, ktoré
              mal pri sebe, zodpovedá za ňu dopravca podľa ustanovení zákona č.
              40/1964 Zb. Občiansky zákonník o zodpovednosti za škodu spôsobenú
              prevádzkou dopravných prostriedkov ( § 427 až 431 ). 5 4. Pokiaľ
              oprávnený uplatňuje škodu spôsobenú na zdraví a veciach, alebo
              škodu spôsobenú odcudzením alebo stratou veci postupuje v zmysle §
              106 Občianskeho zákonníka. 5. Právo na náhradu škody na batožinách
              cestujúceho prepravovaných spoločne s ním alebo veciach, ktoré mal
              cestujúci pri sebe, je cestujúci povinný písomne uplatniť najskôr
              u dopravcu a to najneskôr do 30 dní odo dňa, kedy došlo ku škode,
              alebo do 15 dní odo dňa, keď sa poškodený dozvie o škode. <br /> <br /> 
            </p>
            <p>
            Čl. XII.
              Mimoriadna udalosť<br />  1. Za mimoriadnu udalosť ( ďalej len „MU“ ) pri
              výkone osobnej dopravy – taxislužby sa považuje: a) dopravná
              nehoda, b) požiar vozidla, c) úraz alebo náhle ochorenie
              cestujúceho alebo inej osoby. 2. Pri MU je vodič vozidla
              taxislužby povinný predovšetkým: a) bezodkladne zastaviť vozidlo,
              b) urobiť potrebné opatrenia na záchranu cestujúcich a majetku
              ohrozeného MU, c) poskytnúť podľa svojich schopností a možností
              zranenej osobe potrebnú prvú pomoc a bezodkladne privolať odbornú
              zdravotnícku pomoc, d) urobiť vhodné opatrenia, aby nebola
              ohrozená bezpečnosť cestnej premávky a umožniť jej obnovenie. 3.
              Ak pri MU došlo k zraneniu osoby alebo k jej usmrteniu, poškodeniu
              cesty alebo všeobecne prospešného zariadenia, alebo ak došlo k
              hmotnej škode prevyšujúcej desaťnásobok minimálnej mesačnej mzdy
              zamestnanca, je vodič vozidla taxislužby povinný: a) ohlásiť
              bezodkladne MU policajnému orgánu, b) zdržať sa konania, ktoré by
              bolo na ujmu vyšetrenia MU, c) zotrvať na mieste až do príchodu
              policajného orgánu, alebo na toto miesto sa bezodkladne vrátiť po
              poskytnutí alebo privolaní pomoci, alebo po ohlásení MU. <br /> <br /> 
            </p>
            <p>
            Čl. XIII.
              Záverečné ustanovenia<br />  1. Tento Prepravný poriadok je súčasťou
              zmluvy o preprave, pričom dopravca a cestujúci majú právo v
              písomnej dohode upraviť práva a povinnosti odlišne ako sú upravené
              v Prepravnom poriadku. 2. Cestujúci uzavretím dohody o preprave
              prehlasuje, že ho dopravca oboznámil s právami a povinnosťami
              vyplývajúcimi zo Zmluvy o preprave, ako aj s právami a
              povinnosťami vyplývajúcimi z Prepravného poriadku. 3. Dopravca si
              vyhradzuje právo na zmenu Prepravného poriadku, pričom o zmenách
              bezodkladne informuje cestujúcich formou oznámenia na internetovej
              stránke prepravcu x-auto.sk/ s uvedením dátumu, od ktorého tieto
              zmeny nadobúdajú účinnosť. Tento Prepravný poriadok nadobúda
              platnosť a účinnosť dňom jeho zverejnenia na webovom sídle
              dopravcu. Ak sa dopravca a cestujúci nedohodnú inak, majú právo
              ukončiť vzájomné záväzkové vzťahy a vyrovnať si svoje vzájomné
              pohľadávky.
            </p>
          </div>
        </div>
      </div>
      <div className="about-header">
        <h1>O NÁS</h1>
      </div>
      <div className="about-container">
        <div className="about-wrapper">
          <ul className="about-items">
            <AboutItem
              icon="fas fa-car"
              text="20 ročná prax v oblasti autodopravy"
            />
            <AboutItem
              icon="fas fa-id-card"
              text="Máme všetky potrebné doklady k vykonávaniu tejto činnosti"
            />
          </ul>
          <ul className="about-items">
            <AboutItem
              icon="fas fa-eye"
              text="Každý zákazník ma možnosť k nahliadnutiu dokladov u vodiča"
            />
            <AboutItem
              icon="fas fa-star"
              text="Dbáme na bezpečnosť, spoľahlivosť a diskrétnosť"
            />
          </ul>
        </div>
      </div>
      <div className={clickRule ? 'trbtn desktop' : 'trbtn'}>
        <Link>Prepravný poriadok</Link>
      </div>
      <div className="trbtn mobile">
        <Link to='intro' onClick={closeRules}>Prepravný poriadok</Link>
      </div>
    </div>
  );
}
