import React from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import { photos } from "./Photos";
import './Gallery.css'

export default function GalleryComponent() {

    const BasicRows = () => <Gallery photos={photos} />;

    return (
        <div id="gallery" className="gallery">
            <div className="gallery-header">
                <h1>GALÉRIA</h1>
            </div>
            <div className="gallery-container">
                <BasicRows />
            </div>
        </div>
    )
}
