import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./Contact.css";

export default function Contact() {
  const [name, setName] = useState("");
  const [userEmail, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const submit = () => {
    if (name && userEmail && message) {

      const serviceId = "service_hfo943q";
      const templateId = "template_vt7orda";
      const userId = "vfLENXYDe8ZUPUkVq";
      const templateParams = {
        name,
        userEmail,
        message,
        phone
      };

      emailjs
        .send(serviceId, templateId, templateParams, userId)
        .then((response) => console.log(response))
        .then((error) => console.log(error));

      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
      setEmailSent(true);
      alert("Správa bola úspešne odoslaná.");
    } 
    else {
      alert("Vyplňte povinné polia!");
    }
  };

  return (
    <div className="contact" id="contact">
      <h1>Kontakt</h1>
      <div className="contact-container">
        <div className="container">
          <div className="content">
            <div className="left-side">
              <div className="address details">
                <i className="fas fa-map-marker-alt"></i>
                <div className="topic">Adresa</div>
                <div className="text-two">052 01, Spišská Nová Ves</div>
              </div>
              <div className="phone details">
                <i className="fas fa-phone-alt"></i>
                <div className="topic">Telefón</div>
                <div className="text-one">+421 918 308 148</div>
              </div>
              <div className="email details">
                <i className="fas fa-envelope"></i>
                <div className="topic">Email</div>
                <div className="text-one">profijazda@profijazda.sk</div>
              </div>
              <div className="social details">
                <a href="https://www.facebook.com/profijazdask.italia">
                  <i className="fab fa-facebook-square con-fab"></i>
                </a>
                <a href="https://www.instagram.com/profijazda_sk_italia/">
                  <i className="fab fa-instagram-square con-fab"></i>
                </a>
                <a href="https://youtube.com/channel/UCkyHWG-lI47FIS_49E3i_cA">
                  <i className="fab fa-youtube-square con-fab"></i>
                </a>
              </div>
            </div>
            <div className="right-side">
              <div className="topic-text">Napište nám správu</div>
              <form action="#">
                <div className="input-box">
                  <input
                    type="text"
                    placeholder="Meno *"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="input-box">
                  <input
                    type="text"
                    placeholder="Email *"
                    value={userEmail}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-box">
                  <input
                    type="text"
                    placeholder="Telefón"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="input-box message-box">
                  <textarea
                    type="text"
                    placeholder="Text správy *"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>

                <div className="button">
                  <input type="button" value="Odoslať" onClick={submit} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
