import React from "react";
import Typical from "react-typical";
import "./Intro.css";
import "../../App.css";
import {Link} from 'react-scroll';

export default function Intro() {
  return (
    <div className="intro" id="home">
      <div
        className="intro-container"
      >
        <h3>MEDZINÁRODNÁ PREPRAVA OSÔB</h3>
        <h1>
          {" "}
          <Typical
            loop={Infinity}
            steps={[
              "SPOĽAHLIVOSŤ",
              1000,
              "PRÍSTUP",
              1000,
              "FLEXIBILITA",
              1000,
              "DISKRÉTNOSŤ",
              1000,
            ]}
          />
        </h1>
        <div className="btns-mobile-pos">
          <div className="intro-btns  services-wrap">
            <Link to="services" className="start-btn" smooth={true} duration={1000}>
              NAŠE SLUŽBY
            </Link>
          </div>
          <div className="intro-btns contact-wrap">
            <Link to="contact" className="contact-btn" smooth={true} duration={1000}>
              KONTAKT
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
